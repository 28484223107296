<template>
  <q-layout
    class="bg-white container"
    view="hhh lpr fff"
  >
    <HeaderBlock/>

    <q-page-container>
      <RevenueBlock/>
      <FunctionsBlock/>
      <FormsBlock/>
      <PartnersBlock/>
      <ReviewsBlock/>
      <FaqBlock/>
      <ActionBlock/>
    </q-page-container>

    <FooterBlock/>
  </q-layout>
</template>

<script>
import 'src/css/landing.scss';
import 'src/css/mobile-landing.scss';
import { ActionBlock } from './action';
import { FaqBlock } from './faq';
import { FooterBlock } from './footer';
import { FormsBlock } from './forms';
import { FunctionsBlock } from './functions';
import { HeaderBlock } from './header';
import { PartnersBlock } from './partners';
import { RevenueBlock } from './revenue';
import { ReviewsBlock } from './reviews';

export default {
  name: 'LandingPage',
  components: {
    HeaderBlock,
    RevenueBlock,
    FunctionsBlock,
    FormsBlock,
    PartnersBlock,
    ReviewsBlock,
    FaqBlock,
    ActionBlock,
    FooterBlock,
  },
  beforeCreate() {
    if (process.env.DEV) {
      this.$i18n.locale = 'en_EN';
    } else {
      this.$i18n.locale = 'de_DE';
    }
  },
  mounted() {
    document.body.classList.add('webbymize-landing-home');
  },
  beforeDestroy() {
    document.body.classList.remove('webbymize-landing-home');
  },
};
</script>

<style scoped lang="scss">
.container {
  font-family: Circe, sans-serif;
}
</style>
