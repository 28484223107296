<template>
  <section
    id="faq"
    class="section"
  >
    <div
      v-t="'title.faq'"
      class="title text-center q-mb-lg"
    />
    <q-list>
      <q-expansion-item
        v-for="item in items"
        :key="item.label"
        switch-toggle-side
        class="border-bottom q-pa-lg"
        expand-separator
        :label="item.label"
        icon="fl:plus"
      >
        <q-card>
          <q-card-section>
            <div v-text="item.text"/>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </section>
</template>

<script>
export default {
  name: 'FaqBlock',
  data() {
    return {
      items: [
        {
          label: this.$t('label.isItDifficult'),
          text: this.$t('text.webbymizeIsSimpleToUse'),
        },
        {
          label: this.$t('label.whoIsWebbymize'),
          text: this.$t('text.webbymizeIsSuited'),
        },
        {
          label: this.$t('label.isMyAccountInformation'),
          text: this.$t('text.yourAccountData'),
        },
        {
          label: this.$t('label.whatCanIDo'),
          text: this.$t('text.ifYouRequireHelp'),
        },
        {
          label: this.$t('label.isThereConnection'),
          text: this.$t('text.thereAreConnections'),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
#faq {
  border-bottom: 1px solid $grey-2;
  padding-top: 60px;
  padding-bottom: 80px;

  ::v-deep {
    .q-item__section--avatar {
      display: none;
    }

    .q-expansion-item {
      padding-left: 0;
      padding-right: 0;
    }

    .q-item__label {
      font-family: Circe;
      font-size: 20px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;

      @media screen and (max-width: $breakpoint-xs) {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}
</style>
